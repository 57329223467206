// SLIDE
.home-carousel {
  z-index: 2;
  picture{
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .container{
    max-width: 1200px;
  }
  .carousel-cell{
    width: 100%;
    position: relative;
    overflow: hidden;
    .bg-img{
      position: relative;
      z-index: 1;
    }
    .ratio__content{
      @include media-breakpoint-down(sm) {
        min-height: 500px;
      }
    }
    .texto{
      padding: 5rem 3rem;
      @include media-breakpoint-up(lg) {
        padding: 0 4rem 0 0;
      }
      @include media-breakpoint-down(lg) {
        text-align: center!important;
      }
      h2{
        font-size: 50px;
        @include media-breakpoint-down(md) {
          font-size: 40px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 30px;
        }
      }
    }
    .imagen{
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
  .slick-prev:before, .slick-next:before{
    display: none;
  }
  .slick-prev{
    left: 50px;
    border: 1px solid #fff;
    border-width: 0 0 4px 4px;
    width: 15px;
    height: 15px;
    line-height: 0;
    font-size: 0;
    transform: rotate(45deg);
    z-index: 2;
  }
  .slick-next{
    right: 50px;
    border: 1px solid #fff;
    border-width: 0 0 4px 4px;
    width: 15px;
    height: 15px;
    line-height: 0;
    font-size: 0;
    transform: rotate(-135deg);
    z-index: 2;
  }
}

// Bloque Sobre nosotros
.sobre-nosotros{
  padding: 6rem 2rem;
}

// Bloque Servicios
.servicios{
  padding: 4rem 0;
  .card{
    max-width: 1500px;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    min-height: 350px;
    margin: 0 auto;
  }
  .card-servicios{
    margin: 2rem 0;
    .texto{
      width: 100%;
      padding: 10rem 2rem 2rem;
      position: relative;
    }
  }
  .zoom:hover{
    transition-duration: 1s;
    .over{
      background: rgba(0,0,0,0) -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,99,99,.8))) repeat scroll 0 0;
      background: rgba(0,0,0,0) linear-gradient(transparent,rgba(0,99,99,.8)) repeat scroll 0 0;
      transition-duration: 1s;
    }
  }
}
.main-home{
  .servicios{
    .card{
      max-width: 850px;
    }
    .card-servicios{
      &:nth-child(2n){
        display: flex;
        justify-content: flex-end;
      }
      a {
        @include media-breakpoint-up(lg) {
          min-width: 850px;
        }
        @include media-breakpoint-down(lg) {
          min-width: 100%;
        }
      }
    }
  }
}

// Bloque Soluciones
.soluciones{
  padding-top: 6rem;
  padding-bottom: 0;
  .card{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    min-height: 450px;
    @include media-breakpoint-down(lg) {
      min-height: 350px;
    }
  }
  .lead{
    margin-bottom: 3rem;
  }
  .col-md-6, .col-md-4{
    padding: 0;
    .texto{
      width: 100%;
      padding: 2rem 4rem;
      position: relative;
      @include media-breakpoint-down(md) {
        padding: 2rem;
      }
    }
  }
  .bg-img{
    object-fit: contain;
    background-color: #d2d0d1;
  }
  .zoom:hover{
    transition-duration: 1s;
    .over{
      background: rgba(0,0,0,0) -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,99,99,.8))) repeat scroll 0 0;
      background: rgba(0,0,0,0) linear-gradient(transparent,rgba(0,99,99,.8)) repeat scroll 0 0;
      transition-duration: 1s;
    }
  }
}

// Bloque Productos
.productos{
  position: relative;
  //background-color: #dceaef;
  padding: 4rem 0rem 4.5rem;
  &::after{
    content: "";
    position: absolute;
    background-color: $white;
    width: 100%;
    height: 400px;
    top: 0;
  }
  .container{
    position: relative;
    z-index: 2;
  }
  .lead{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  img{
    width: 100%;
  }
  .card{
    background-color: transparent;
    overflow: hidden;
  }
  .texto{
    padding: 1.5rem;
    margin-bottom: 0;
    h3{
      font-size: 18px;
    }
    p{
      font-size: 15px;
      margin-bottom: 0;
    }
  }
  .zoom:hover{
    transition-duration: 1s;
    .bg-green{
      transition-duration: 1s;
      background-color: $vi4-02;
    }
  }
  @media (min-width: 1201px) {
    .texto{
      position: absolute;
      width: 85%;
      bottom: 0;
      left: 0;
      padding: 2rem;
    }
  }
  @media (max-width: 1200px) {
    .card{
      margin-bottom: 4rem;
    }
  }
}

// Bloqe Puntos
.puntos{
  .container{
    max-width: 1200px;
  }
  .card{
    padding: 2rem;
    background-color: transparent;
    img{
      padding: 0 1rem;
      max-width: 200px;
      height: 200px;
      margin: 1rem auto 2rem;
    }
    h3{
      font-size: 25px;
    }
  }
}

// Bloque Noticias
.noticias{
  .card{
    background-color: $white;
    .imagen{
      position: relative;
      overflow: hidden;
    }
    .texto{
      padding: 2rem;
      color: $black;
      h3{
        font-size: 18px;
      }
    }
    .fecha{
      color: #7f7f7f;
      font-size: 13px;
    }
    .over{
      background: rgba(100,187,182,.8);
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all .3s ease;
      &::after{
        content: url('/media/theme/img/logo-icon.svg');
        width: 50px;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 45%;
        left: 45%;
      }
    }
    &:hover{
      .over{
        opacity: 1;
      }
      h3{
        color: $vi4-01;
        transition: all .3s ease;
      }
    }
  }
  .col-md-12{
    padding: 4rem 0 0;
  }
}
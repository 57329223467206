.ratio {
	display: flex;
	position: relative;
}

.ratio__content {
	position: relative;
	width: 100%;
  z-index: 3;
}

.ratio-2-1 {
	&:before {
		content: '';
		padding-bottom: (1/2) * 100%;
	}
}
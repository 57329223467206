// Soluciones
.main-soluciones.categoria{
  .section-intro{
    .lead{
      .doscolumnas{
        column-count: 2;
      }
    }
  }
  .bg-black{
    padding: 8rem 2rem;
  }
}
.main-soluciones.detalle{
  nav{
    padding: 0;
    .col{
      padding: 0;
    }
    .breadcrumb{
      justify-content: left!important;
      padding-left: 0.4rem;
    }
  }
  .galeria{
    margin-top: 4rem;
  }
  .card-control-procesos{
    .card{
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      justify-content: center;
      min-height: 350px;
      margin: 0 auto;
      .texto{
        padding: 2rem;
      }
    }
  }
  .slider-soluciones{
    .card {
      background-color: transparent;
      .h3{
        font-family: 'Montserrat Bold';
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 1.5rem;
        color: $body-color;
      }
    }
  }
}
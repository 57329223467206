// Foter
.footer{
  position: relative;
  z-index: 2;
  padding: 3rem 0 0;
  .bg-black{
    padding: 3rem 0;
  }
  a{
    color: $black;
  }
  .list-unstyled{
    li{
      padding: 1rem;
    }
  }
  .form-newsletter{
    font-size: 13px;
    a{
      color: $white;
      font-weight: 600;
    }
    .form-check-label, p{
      color: $white;
    }
  }
  .form-control{
    padding: 0.7rem 0.7rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $black!important;
    background-color: #fff;
    border: 1px solid $black;
    border-radius: 0!important;
    &::placeholder{
      color: $black!important;
    }
  }
  .form-control{
    &::placeholder{
      font-size: 13px;
    }
  }
  button{
    color: $white;
    border: 1px solid $white;
    background-color: transparent;
  }
  .bottom{
    font-size: 13px;
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    .top{
      flex-wrap: wrap;
      li{
        width: 100%;
        font-size: 14px;
        padding: 0.5rem;
      }
    }
  }
  @media (max-width: 600px) {
    .bottom{
      flex-wrap: wrap;
      li{
        width: 100%;
      }
    }
  }
}
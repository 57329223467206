// Sectores
.breadcrumb{
  background-color: transparent;
  justify-content: center;
  &:not(.no-white) {
    a {
      color: $white;
    }
  }
  .active{
    span{
      font-family: 'Montserrat Bold';
      color: $black;
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: $black;
    content: ">";
  }
}

.bg-black {
  .breadcrumb {
    .active {
      span {
        color: $white;
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      color: $white;
    }
  }
}

.px-0 {
  ol.breadcrumb {
    padding-left: 0;
    padding-right: 0;
  }
}
.nav-container{
  position: relative;
  z-index: 4;
    .logotipo-container{
      position: absolute;
      background-color: $white;
      max-width: 350px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      .container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      img{
        max-width: 240px;
        height: auto;
      }
      .triangle {
        position: absolute;
        width: 0;
        height: 0;
        right: -127px;
        border-top: 146px solid #fff;
        border-right: 127px solid transparent;
      }
      @media (max-width: 1600px){
        max-width: 200px;
        .navbar-brand{
          margin: 0;
        }
        img{
          max-width: 180px;
          height: auto;
        }
        .triangle {
          right: -110px;
          border-right: 110px solid transparent;
        }
        .bg-01 {
          padding: 2.2rem 1rem;
        }
      }
      @media (max-width: 1300px){
        height: 100px;
        img{
          padding: 1rem;
        }
        .navbar-brand{
          margin: 0;
        }
        .triangle {
          right: -80px;
          border-right: 80px solid transparent;
          border-top: 100px solid #fff;
        }
      }
      @media (max-width: 767px){
        height: 94px;
        .triangle{
          border-top: 94px solid #fff;
        }
      }
    }
    // Idiomas
    .list-footer {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      font-size: 0;
  }
  .list-footer li:first-child {
      margin-left: 0;
  }
  .list-footer li {
      font-size: 15px;
      display: inline-block;
      margin-left: 20px;
      position: relative;
      overflow: hidden;
  }
  .select-footer {
      margin-left: 35px;
      border: 0;
      height: inherit;
      padding: 0;
      position: relative;
  }
  select {
      font-size: 15px;
      display: inline-block;
      width: 48px;
      height: 48px;
      padding: 6px 10px;
      background-color: transparent;
      border: 0px;
      color: $white;
      max-width: 100%;
      vertical-align: top;
      appearance: none;
  }
  .listitem-language::after {
      content: "";
      position: absolute;
      display: block;
      cursor: pointer;
      background: 0 0;
      color: transparent;
      border: 1px solid #fff;
      border-width: 0 0 2px 2px;
      width: 8px;
      height: 8px;
      line-height: 0;
      top: 6px;
      right: 10px;
      font-size: 0;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-50deg);
      z-index: 2;
    }
    // Menu general
    .menu{
      .row{
        margin: 0;
      }
      .col-md-12{
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      a{
        color: $black;
        font-size: 14px;
      }
      .linkedin{
        border: 1px solid $white;
        padding: 0.25rem 0.5rem;
        svg{
          width: 13px;
          height: 13px;
        }
      }
      .burger{
        svg{
          width: 15px;
          height: 15px;
        }
      }
      @media (max-width: 991px){
        a{
          font-size: 12px;
        }
      }
    }
}
.bg-01{
  background-color: $vi4-01;
  padding: 0.8rem 1rem;
  a{
    margin-left: 1.5rem;
  }
  @media (max-width: 1300px){
    padding: 2.2rem 1rem!important;
  }
}
.bg-02{
  background-color: $vi4-02;
  padding: 0;
}
// Mega menu
.navbar{
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 1px 3px 4px 0 #adadad33;
  .container-fluid{
    display: flex;
    justify-content: end;
    button{
      border: 0;
      padding: 1rem 0;
    }
    .navbar-light .navbar-toggler-icon svg{
      color: $white;
    }
  }
  @media (min-width: 1300px){
    .navbar-collapse{
      justify-content: end;
    }
  }
  @media (max-width: 1299px){
    .navbar-collapse{
      justify-content: center;
    }
  }
  @media (max-width: 991px){
    padding: 0;
    button{
      padding: 2rem!important;
    }
  }
}
.navbar-light .navbar-nav .nav-link{
  color: $white;
  font-size: 16px;
  padding: 2rem 0 2rem 4rem;
  transition: 0.3s;
  &:hover{
    opacity: 0.8;
    transition: 0.3s;
  }
  @media (max-width: 1700px){
    padding: 2rem 0 2rem 2rem;
    font-size: 15px;
  }
  @media (max-width: 1300px){
    padding: 2rem 1rem;
  }
  @media (max-width: 1150px){
    padding: 1rem;
    font-size: 13px;
  }
  @media (max-width: 991px){
    padding: 1rem 0 1rem 1rem;
    font-size: 14px;
    .dropdown-item{
      font-size: 14px;
    }
  }
}
@media (min-width: 992px){
  .dropdown-toggle::after{
    display: none;
  }
}
.sm-menu{
  border-radius: 0px;
  border: 0px;
  top: 97%;
  box-shadow: rgba(173, 173, 173, 0.2) 1px 3px 4px 0px;
}
.dropdown-menu{
  .col-lg-12{   
    .dropdown-item{
      color: $black;
      padding: 1rem;
    }
    @media (min-width: 992px){
      display: flex;
      justify-content: center;
      text-align: center;
    }
    @media (max-width: 991px){
      .dropdown-item{
        padding: 0.5rem 0;
      }
    }
  }
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2196F3;
}
.navbar-collapse{
  @media (min-width: 992px){
    display: flex;
    justify-content: start;
    width: 100%;
  }
}
.navbar-toggler{
  outline: none !important;
}
.navbar-tog{
  color: #1ebdc2;
}
.megamenu-li{
  position: static;
}
.megamenu{
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}
@media (min-width: 1201px){
  .mobile {
      display: none;
  }
}
@media (max-width: 1200px){
  .computer {
      display: none;
  }
}
.logotipo{
  max-width: 150px;
}
.search {
  img{
    width: 12px;
    height: 12px;
    fill: $white;
  }
}
.bg-yellow{
  button{
    svg{
      width: 18px;
      height: 18px;
    }
  }
  nav {
    position: relative;
    display: flex;
    align-items: center;
    ul{
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      li{
        display: inline-block;
        font-size: 14px;
        padding: 0 15px;
        position: relative;
        a{
          font-family: 'Oswald Medium';
          text-transform: uppercase;
          text-decoration: none;
          color: $gray;
          font-size: 15px;
          letter-spacing: 1px;
          display: block;
          padding: 20px 0;
          transition: all .3s ease;
        }
      }
    }
  }
  .mega-menu {
    background: #eee;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 1rem;
    z-index: 4;
  }
  .mega-menu .container {
    display: flex;
  }
  .mega-menu .item {
    flex-grow: 1;
    margin: 0 10px;
  }
  .mega-menu .item img {
    width: 100%;
  }
  .mega-menu a {
    border-bottom: 1px solid #ddd;
    color: #4ea3d8;
    display: block;
    padding: 10px 0;
  }
  .dropdown {position: static;}
  .dropdown:hover .mega-menu {
    visibility: visible;
    opacity: 1;
  }
}
@media (min-width: 768px){
.d-none-ordenador{
    display: none;
  }
}
@media (max-width: 767px){
.d-none-movil{
    display: none!important;
  }
}

.card-body-header {
	padding: 8px 15px;
}

.nav-container .menu .row-header {
	margin-right: -15px;
	margin-left: -15px;
}

.link-subcat {
	color: #000;
	padding-top: 8px;
	padding-bottom: 8px;
	&:hover {
		background-color: #f8f9fa;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

.btn-collapse {
	transition: transform .3s ease;
	&:not(.collapsed) {
		transform: rotate(-180deg);
	}
}

@include media-breakpoint-up(lg) {
	.nav-item {
		.border-top {
			display: block;
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			transition: opacity .3s ease;
			visibility: hidden;
		}
		&:hover {
			.nav-link { color: rgba($black, .9) }
			.border-top {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
// Noticias
.main-noticias{
  .categorias{
      width: 100%;
      display: flex;
      justify-content: center;
      list-style: none;
      margin-bottom: 5rem;
    a{
      color: $white;
      padding: 2rem;
      &:hover {
        opacity: 0.8;
        transition: 0.3s;
        color: $navbar-light-hover-color;
      }
      &.active {
        color: $navbar-light-active-color;
      }
    }
  }
  .pagination {
    .page-link{
      background-color: transparent;
      border: 0;
      color: white;
    }
    .page-item {
      &.active {
        .page-link {
          font-weight: $font-weight-bold;
          cursor: default;
        }
      }
      &.disabled {
        .page-link {
          background: none;
          border: 0;
          color: rgba(255,255,255,.5);
        }
      }
    }
  }
}
.novedades{
  .card{
    background-color: transparent;
    .imagen{
      position: relative;
      overflow: hidden;
    }
    .texto{
      padding: 2rem 1rem;
      color: $white;
      h3{
        font-size: 18px;
      }
    }
    .over{
      background: rgba(100,187,182,.8);
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all .3s ease;
      &::after{
        content: url('/media/theme/img/logo-icon.svg');
        width: 50px;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 45%;
        left: 45%;
      }
    }
    &:hover{
      .over{
        opacity: 1;
      }
    }
  }
  .col-md-12{
    padding: 4rem 0 0;
  }
}
.main-noticias.detalle{
  .cabecera{
    align-items: center;
    h1,.breadcrumb a{
      color: $black;
    }
  }
  h1 {
    font-size: 45px;
  }
  .imagen.intro{
    .col-md-6{
      padding:0 2rem;
    }
  }
}

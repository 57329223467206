// Sectores
.main-sectores.categoria{
  .card-servicios{
    margin-bottom: 4rem;
  }
  .card{
    padding: 3rem;
    max-width: 700px;
    min-height: 400px;
    height: 100%;
    margin: 0 auto;
    .texto{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
.main-sectores.detalle{
  .container{
    max-width: 1100px;
  }
  nav{
    padding: 0;
    .col{
      padding: 0;
    }
    .breadcrumb{
      justify-content: center!important;
      padding-left: 0.4rem;
    }
  }
  .texto{
    padding: 5rem 0;
  }
}
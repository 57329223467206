// Control de proceso
.main-procesos{
  .col-md-4{
    overflow: hidden;
  }
  .bg-gray{
    background-color: #e7e7e7;
    padding: 6rem 0 8rem;
    .row{
      align-items: center;
      .col-md-8{
        padding: 3rem 5rem;
        color: $black;
        p{
          margin-bottom: 0;
        }
      }
    }
  }
}
// Corporativo
.main-contacto{
  .mapa-container{
    position: relative;
    height: 900px;
    .gmap{
      height: 100%;
    }
  }
  .bg-white{
    background-color: rgba(255,255,255,0.9)!important;
    padding: 3rem;
  }
  .cabecera{
    position: absolute;
    width: 100%;
    bottom: 0;
    .col{
      padding-left: 0;
    }
    .breadcrumb{
      justify-content: left;
      padding-left: 0.4rem;
      a{
        color: $black;
      }
      .active{
        color: $vi4-02;
      }
    }
    .text-left{
      text-align: left!important;
    }
    .lead{
      font-size: 17px;
    }
    li{
      display: flex;
      align-items: center;
    }
    svg{
      color: $vi4-02;
      width: 35px;
      height: 35px;
      margin-right: 0.2rem;
    }
    .sobre{
      svg{
        padding-right: 0.8rem;
      }
    }
    @media (min-width: 1200px){
      .col-lg-5{
        padding-right: 5rem;
      }
    }
  }
  /* Select formularios */
  .custom-select--white {
    //background: transparent $custom-select-indicator--white no-repeat right $custom-select-padding-x center;
    color: #fff;
    option {
      background-color: #000;
      color: #fff;
    }
  }
  .legales{
    text-align: end;
  }
}
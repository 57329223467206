// Buscador
.sb-search {
  position: relative;
  width: 0%;
  min-width: 30px;
  height: 30px;
  float: right;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  transition: width 0.3s;
  -webkit-backface-visibility: hidden;
  img{
    padding: 0.4rem;
  }
}
.sb-search-input {
  position: absolute;
  top: 0;
  right: -30px;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: 30px;
  margin: 0;
  z-index: 10;
  font-family: inherit;
  font-size: 15px;
  color: $white;
}
input[type="search"].sb-search-input {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}
.sb-search-input::-webkit-input-placeholder {
  color: $white;
}
.sb-search-input:-moz-placeholder {
  color: $white;
}
.sb-search-input::-moz-placeholder {
  color: $white;
}
.sb-search-input:-ms-input-placeholder {
  color: $white;
}
.sb-icon-search,
.sb-search-submit  {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  img{
    width: 30px;
    height: 30px;
  }
}
.sb-search-submit {
  background: transparent;
  opacity: 0;
  color: transparent;
  color:red;
  border: none;
  outline: none;
  z-index: -1;
}
.sb-icon-search {
  color: #fff;
  z-index: 90;
  font-size: 15px;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.sb-search.sb-search-open,
.no-js .sb-search {
  width: 30%;
  @media (max-width: 767px){
    width: 100%;
  }
}
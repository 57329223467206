
// Montserrat
@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), url('../theme/fonts/montserrat/Montserrat-Regular.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Italic'), url('../theme/fonts/montserrat/Montserrat-Italic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin'), url('../theme/fonts/montserrat/Montserrat-Thin.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin Italic'), url('../theme/fonts/montserrat/Montserrat-ThinItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraLight'), url('../theme/fonts/montserrat/Montserrat-ExtraLight.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraLight Italic'), url('../theme/fonts/montserrat/Montserrat-ExtraLightItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Light';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light'), url('../theme/fonts/montserrat/Montserrat-Light.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light Italic'), url('../theme/fonts/montserrat/Montserrat-LightItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium'), url('../theme/fonts/montserrat/Montserrat-Medium.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium Italic'), url('../theme/fonts/montserrat/Montserrat-MediumItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat SemiBold'), url('../theme/fonts/montserrat/Montserrat-SemiBold.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat SemiBold Italic'), url('../theme/fonts/montserrat/Montserrat-SemiBoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold'), url('../theme/fonts/montserrat/Montserrat-Bold.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold Italic'), url('../theme/fonts/montserrat/Montserrat-BoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraBold'), url('../theme/fonts/montserrat/Montserrat-ExtraBold.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraBold Italic'), url('../theme/fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Black';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Black'), url('../theme/fonts/montserrat/Montserrat-Black.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Black Italic'), url('../theme/fonts/montserrat/Montserrat-BlackItalic.woff') format('woff');
  }
.montserrat-regular{
    font-family: 'Montserrat Regular';
}
.montserrat-thin{
    font-family: 'Montserrat Thin';
}
.montserrat-extra-light{
    font-family: 'Montserrat ExtraLight';
}
.montserrat-light{
    font-family: 'Montserrat Light';
}
.montserrat-medium{
    font-family: 'Montserrat Medium';
}
.montserrat-semi-bold{
    font-family: 'Montserrat SemiBold';
}
.montserrat-bold{
    font-family: 'Montserrat Bold';
}
.montserrat-extra-bold{
    font-family: 'Montserrat ExtraBold';
}
.montserrat-black{
    font-family: 'Montserrat Black';
}

// Nunito
@font-face {
  font-family: 'Nunito Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Regular'), url('../theme/fonts/nunito/Nunito-Regular.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Italic'), url('../theme/fonts/nunito/Nunito-Italic.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito ExtraLight'), url('../theme/fonts/nunito/Nunito-ExtraLight.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito ExtraLight Italic'), url('../theme/fonts/nunito/Nunito-ExtraLightItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito Light';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Light'), url('../theme/fonts/nunito/Nunito-Light.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Light Italic'), url('../theme/fonts/nunito/Nunito-LightItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito SemiBold'), url('../theme/fonts/nunito/Nunito-SemiBold.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito SemiBold Italic'), url('../theme/fonts/nunito/Nunito-SemiBoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Bold'), url('../theme/fonts/nunito/Nunito-Bold.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Bold Italic'), url('../theme/fonts/nunito/Nunito-BoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito ExtraBold'), url('../theme/fonts/nunito/Nunito-ExtraBold.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito ExtraBold Italic'), url('../theme/fonts/nunito/Nunito-ExtraBoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito Black';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Black'), url('../theme/fonts/nunito/Nunito-Black.woff') format('woff');
  }
  @font-face {
  font-family: 'Nunito Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito Black Italic'), url('../theme/fonts/nunito/Nunito-BlackItalic.woff') format('woff');
  }
.nunito-regular{
    font-family: 'Nunito Regular';
}
.nunito-thin{
    font-family: 'Nunito Thin';
}
.nunito-extra-light{
    font-family: 'Nunito ExtraLight';
}
.nunito-light{
    font-family: 'Nunito Light';
}
.nunito-semi-bold{
    font-family: 'Nunito SemiBold';
}
.nunito-bold{
    font-family: 'Nunito Bold';
}
.nunito-extra-bold{
    font-family: 'Nunito ExtraBold';
}
.nunito-black{
    font-family: 'Nunito Black';
}

@function rem($base) {
  $result: ($base / 16) + rem;
  @return $result;
}

$sizes: 14, 16, 20;

@each $size in $sizes {
  .fs-#{$size} {
    font-size: rem($size) !important;
  }
}

.productos .texto .fs-14 p {
	font-size: rem(14);
}
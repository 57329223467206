// Productos
.main-productos{
  .productos::after {
    height: 200px;
  }
}
.main-productos.detalle{
  .container{
    max-width: 1100px;
  }
  .cabecera{
    .text-left{
      text-align: left!important;
    }
  }
  nav{
    padding: 0;
    .col{
      padding: 0;
    }
    .breadcrumb{
      justify-content: left!important;
      padding-left: 0.4rem;
    }
  }
  .texto{
    padding: 5rem 0;
  }
}
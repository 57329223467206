.validation-form{
  .invalid-feedback{
    display: block;
  }
}
.form-control{
  padding: 0.7rem 0.7rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $white!important;
  background-color: transparent;
  border: 1px solid $white;
  border-radius: 0!important;
  &::placeholder{
    color: $white!important;
  }
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 11px);
  font-size: 13px;
}
.form-bottom{
  padding: 6rem 0;
  .sobre{
    display: flex;
    justify-content: end;
    svg{
      color: $white;
      width: 60px;
      height: 60px;
    }
  }
  a{
    color: $white;
    font-weight: 600;
  }
  .form-check-label, p{
    color: $white;
  }
  .form-control{
    &::placeholder{
      font-size: 13px;
    }
  }
  .form-control:focus{
    background-color: transparent;
  }
  .legales{
    font-size: 13px;
    .form-check-label {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
  }
  button{
    color: $white;
    border: 1px solid $white;
    background-color: transparent;
  }
}

.custom-select--white {
	option {
		background-color: $black;
		color: #fff;
		font-family: $font-family-sans-serif;
		padding-right: 2rem!important;
	}
}
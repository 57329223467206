/*
  # Background image with object-fit
  # Backgrounds
  # Opacities
  # Contenidos
  # Márgenes
  # Enlaces
  # Over
  # Encabezados
  # Cards
  # Mosca
*/

// # Background image with object-fit
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; // esto va ligado con el plugin de https://github.com/fregante/object-fit-images
  /* Si se quiere la posición:
  object-position: bottom;
  font-family: 'object-fit: cover; object-position: bottom;';
  */
}
// # Backgrounds
.bg-black{
  background-color: $black;
}
.bg-green{
  background-color: $vi4-01;
}
.bg-green-v2{
  background-color: $vi4-02;
}
.bg-gray{
  background-color: #f6f6f6;
}
.bg-light-grey{
  background-color: #ececec;
}

// # Opacities
@each $opacity in $opacities {
  .opacity-#{$opacity} {
   opacity: #{$opacity/100};
  }
}

// # Contenidos
.main-home{
  position: relative;
  &::after{
    content: url('/media/theme/img/logo-icon.svg');
    position: fixed;
    width: 450px;
    top: 60%;
    right: 0;
    z-index: 1;
  }
}
.detalle{
  .cabecera{
    align-items: end;
    display: flex;
    flex-wrap: wrap;
    min-height: 500px;
    h1,nav{
      width: 100%;
      position: relative;
      z-index: 2;
    }
  }
}
.bloque{
  padding-bottom: 3rem;
  .imagen{
    overflow: hidden;
    margin-bottom: 2rem;
    img{
      width: 100%;
    }
  }
  .doscolumnas{
      column-count: 2;
      @media (min-width: 768px){
        p, li{
          padding-right: 3rem;
        }
      }
  }
  .texto{
    h2, h3, h4, h5{
      font-family: 'Montserrat Bold';
      margin-bottom: 1.2rem;
    }
    p, ul, li{
      font-family: 'Nunito Regular';
    }
  }
  .galeria{
    .slick-track{
      margin: 0;
      .slick-slide{
        padding: 0 2rem;
      }
    }
    .slider-nav{
      .slick-slide{
        padding-right: 1rem;
      }
      .slick-slide.slick-current.slick-active{
        .imagen{
          border: 4px solid $vi4-02;
        }
      }
    }
    .slick-prev:before, .slick-next:before{
      display: none;
    }
    .slick-prev{
      border: 1px solid #000;
      border-width: 0 0 4px 4px;
      width: 15px;
      height: 15px;
      line-height: 0;
      font-size: 0;
      transform: rotate(45deg);
      z-index: 2;
    }
    .slick-next{
      border: 1px solid #000;
      border-width: 0 0 4px 4px;
      width: 15px;
      height: 15px;
      line-height: 0;
      font-size: 0;
      transform: rotate(-135deg);
      z-index: 2;
    }
  }
}
@media (min-width: 1200px){
  .container {
      max-width: 1500px;
  }
  .container-fluid{
      max-width: 1700px;
  }
}

// # Márgenes
.seccion{
  position: relative;
  padding: 4rem 1rem;
  z-index: 2;
}

// # Enlaces
a{
  color: $vi4-01;
  &:hover{
    color: $vi4-02;
    text-decoration: none;
  }
}

// # Over
.over{
  background: rgba(0,0,0,0) -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.8))) repeat scroll 0 0;
  background: rgba(0,0,0,0) linear-gradient(transparent,rgba(0,0,0,.8)) repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

// # Encabezados
.h1{
  font-size: 60px;
  @media (max-width: 1200px){
    font-size: 45px;
  }
}
.h2{
  font-size: 50px;
  @media (max-width: 1200px){
    font-size: 32px;
  }
  @media (max-width: 767px){
    font-size: 26px;
  }
}
.h3{
  font-size: 40px;
  @media (max-width: 1200px){
    font-size: 27px;
  }
  @media (max-width: 767px){
    font-size: 24px;
  }
}
.section-intro{
  .lead{
    font-size: 18px;
  }
}
.border-bottom{
  border-bottom: 2px solid #e5e5e5!important;
}

// # Cards
.card{
  border: 0;
  border-radius: 0;
  transition: all .3s ease;
  .texto{
    z-index: 3;
  }
}
.zoom{
  overflow: hidden;
  padding: 0;
}
.zoom img {
  transition-duration: 1s;
  margin: 0 auto;
  display: block;
}
.zoom:hover img{
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  z-index: 0;
}

// # Mosca
.mosca{
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #008181, #006869);
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 3;
  svg{
    width: 40px;
    color: $white;
  }
}

.wb {
	hyphens: auto;
	word-break: break-word
}
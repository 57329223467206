/*
  # Reset
  # Outline
*/


// # Reset
.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  //font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: none;
  }
}

// # Outline
.no-outline {
  &:focus {
    outline: none;
  }
}

// # Vi4crane
.btn-vi4crane{
  padding: 0.8rem 3rem;
  background-color: $vi4-02;
  border-radius: 0px;
  color: $white;
  text-transform: uppercase;
  font-size: 14px;
}
.button_slide {
  display: inline-block;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 $vi4-01;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  &:hover {
    box-shadow: inset 0 0 0 50px $vi4-01;
    color: $white;
  }
}
.btn-save-config{
  padding: 0.8rem 3rem;
  background-color: $vi4-02;
  border-radius: 0px;
  color: $white;
  text-transform: uppercase;
  font-size: 14px;
}
// Corporativo
.main-corporativo{
  .breadcrumb a{
    color: $black;
  }
  .col-lg-6{
    overflow: hidden;
    .imagen{
      max-width: 600px;
      margin: 0 auto;
    }
  }
}
// Bloques
.bloques{
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  .row {
    margin-bottom: 4rem;
  }
  .imagen{
    overflow: hidden;
    margin-bottom: 2rem;
    img{
      width: 100%;
      height: auto;
    }
  }
  .texto{
    h2, h3, h4, h5{
      font-family: 'Montserrat Bold';
      margin-bottom: 1.2rem;
      &.h3 {
        font-size: 1.75rem;
      }
    }
    p, ul, li{
      font-family: 'Nunito Regular';
    }
  }
  .galeria{
    .slick-track{
      margin: 0;
      .slick-slide{
        padding: 0 2rem;
      }
    }
    .slider-nav{
      .slick-slide{
        padding-right: 1rem;
      }
      .slick-slide.slick-current.slick-active{
        .imagen{
          border: 4px solid $vi4-02;
        }
      }
    }
    .slick-prev:before, .slick-next:before{
      display: none;
    }
    .slick-prev{
      border: 1px solid #000;
      border-width: 0 0 4px 4px;
      width: 15px;
      height: 15px;
      line-height: 0;
      font-size: 0;
      transform: rotate(45deg);
      z-index: 2;
    }
    .slick-next{
      border: 1px solid #000;
      border-width: 0 0 4px 4px;
      width: 15px;
      height: 15px;
      line-height: 0;
      font-size: 0;
      transform: rotate(-135deg);
      z-index: 2;
    }
  }
  .bg-gray {
    padding: 5rem 0;
  }
  .bg-black {
    padding: 8rem 2rem;
  }
  .doscolumnas{
    column-count: 2;
    @media (min-width: 768px){
      p, li{
        padding-right: 3rem;
      }
    }
  }
}